<template>
  <div class="my-4">
    <CRow align-horizontal="center">
      <CCol sm="12"> 
        <CInput
          v-model.trim="buqueIMO"
          v-uppercase
          :horizontal="{ label: 'col-sm-12 col-lg-2', input: 'col-sm-12 col-lg-8'}"
          disabled
          size="sm"
          :label="$t('label.vessel')"
          addLabelClasses="text-right"
          maxlength="200"
        >
        </CInput>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12" lg="5">
        <CInput
          v-model.trim="$v.bodega.VesselHoldName.$model"
          v-uppercase
          :horizontal="{ label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7'}"
          :invalid-feedback="$t('label.required')+$t('label.onlyText')"
          :is-valid="hasError($v.bodega.VesselHoldName)"
          size="sm"
          :label="$t('label.holdName')"
          addLabelClasses="text-right required"
          maxlength="150"
        >
        </CInput>
      </CCol>
      <CCol sm="12" lg="5" v-if="isCargoVessel">
        <div class="form-group form-row">
          <label class="text-right col-sm-12 col-lg-5 required col-form-label-sm">{{ $t('label.baleCapacity')+'(M³)' }}</label>
          <div class="input-group col-sm-12 col-lg-7 input-group-sm">
            <money
              v-uppercase
              v-model.trim="$v.bodega.Bale.$model"
              v-bind="measure" 
              :class="ValidateFormControl(bodega.Bale, true)"
            >
            </money>
            <div class="text-invalid-feedback" v-if="($v.bodega.Bale.$error && valid==true)">
              {{$t('label.required')}}
            </div>
          </div>
        </div>
      </CCol>
      <CCol sm="12" lg="5" v-if="isCargoVessel">
        <div class="form-group form-row">
          <label class="text-right col-sm-12 col-lg-5 required col-form-label-sm">{{ $t('label.grainCapacity')+'(M³)' }}</label>
          <div class="input-group col-sm-12 col-lg-7 input-group-sm">
            <money
              v-uppercase
              v-model.trim="$v.bodega.BaleOnGrain.$model"
              v-bind="measure" 
              :class="ValidateFormControl(bodega.BaleOnGrain, true)"
            >
            </money>
            <div class="text-invalid-feedback" v-if="($v.bodega.BaleOnGrain.$error && valid==true)">
              {{$t('label.required')}}
            </div>
          </div>
        </div>
      </CCol>
      <CCol sm="12" lg="5" v-if="isCargoVessel">
        <div class="form-group form-row">
          <label class="text-right col-sm-12 col-lg-5 col-form-label-sm">{{ $t('label.baleCapacity')+' '+$t('label.onDeck')+' (M³)' }}</label>
          <div class="input-group col-sm-12 col-lg-7 input-group-sm">
            <money
              v-uppercase
              v-model.trim="$v.bodega.BaleOnDeck.$model"
              v-bind="measure" 
              :class="ValidateFormControl(bodega.BaleOnDeck, false)"
            >
            </money>
          </div>
        </div>
      </CCol>
      <CCol sm="12" lg="5" v-if="isGeneralCargo">
        <div class="form-group form-row">
          <label class="text-right col-sm-12 col-lg-5 col-form-label-sm">{{ `${$t('label.volume')} (M³)` }}</label>
          <div class="input-group col-sm-12 col-lg-7 input-group-sm">
            <money
              v-uppercase
              v-model.trim="$v.bodega.Volumen.$model"
              v-bind="measure" 
              :class="ValidateFormControl(bodega.Volumen, true)"
            >
            </money>
          </div>
          <div class="text-invalid-feedback" v-if="($v.bodega.Volumen.$error && valid==true)">
            {{$t('label.required')}}
          </div>
        </div>
      </CCol>
      <CCol sm="12" lg="5" v-if="edit">
        <CSelect
          :value.sync="bodega.Status"
          :is-valid="statusSelectColor"
          size="sm"
          :label="$t('label.status')"
          :horizontal="{ label: 'col-sm-12 col-lg-5', input: 'col-sm-7 col-lg-7'}"
          :options="statusOptions"
          addLabelClasses="text-right"
          class="mt-1"
        />
      </CCol>
    </CRow>
    <!--
    <CCollapse >
      <CRow>
        
      </CRow>
    </CCollapse>
    -->
    <CRow>
      <CCol sm="12" class="d-flex justify-content-end align-items-center">
        <CButton
          size="sm"
          color="add"
          shape="square"
          :disabled="isSubmitValid"
          class="d-flex align-items-center mr-2"
          @click.stop="statusConfirmation"
        >
          <div v-if="!isSubmit">
            <CIcon name="checkAlt"/>
            <span class="ml-1">{{ submitText }}</span>
          </div>
          <div v-if="isSubmit">
            <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="ml-1">{{ submitText }}</span>
          </div>
        </CButton>
        <CButton
          size="sm"
          color="wipe"
          shape="square"
          :disabled="isSubmit"
          class="d-flex align-items-center"
          @click.stop="resetInputs"
          v-c-tooltip="{
            content: $t('label.clearFields'),
            placement: 'top-end'
          }"
          >
          <CIcon name="cil-brush-alt" />
        </CButton>
      </CCol>
    </CRow>
    <CRow class="mt-4">
      <CCol sm="12">
        <dataTableExtended
     class="align-center-row-datatable"
          hover
          sorter
          column-filter
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :items="formatedBodegas"
          :fields="fields"
          :loading="isLoading"
          :noItemsView="tableText.noItemsViewText"
          :items-per-page="5"
          :active-page="activePage"
          pagination
           
        >
          <template #loading>
            <loading/>
          </template>
          <template #RowNumber="{ item }">
            <td class="center-cell text-center">
              {{ item.RowNumber }}
            </td>
          </template>

          <template #Status="{item}">
            <td class="center-cell text-center">
              <CBadge :color="getBadge(item.Status)">
                {{ $t('label.'+item.Status) }}
              </CBadge>
            </td>
          </template>

          <template #Details="{item}">
            <td class="py-2 center-cell text-center">
                <CButton
                  color="edit"
                  size="sm"
                  class="mr-2"
                  :disabled="edit"
                  v-c-tooltip="{
                    content: $t('label.edit')+$t('label.hold'),
                    placement: 'top-end'
                  }"
                  @click.stop="handleEdit(item)"
                >
                  <CIcon name="pencil" />
                </CButton>
                <CButton
                  color="add"
                  size="sm"
                  class="mr-2"
                  :disabled="edit"
                  v-c-tooltip="{
                    content: $t('label.nueva')+$t('label.hatchCover'),
                    placement: 'top-end'
                  }"
                  @click.stop="handleBayHatchAdd(item, 'HATCH')"
                >
                  <CIcon name="cil-layers"/>
                </CButton>
            </td>
          </template>
        </dataTableExtended>
      </CCol>
      <hatch-cover-modal :modal.sync="modalHatchCover" :hold="hold" @submited="handleSubmit"/>
    </CRow>
  </div>
</template>

<script>
import UpperCase  from '@/_validations/uppercase-directive';
import BuqueBodegaValidations from '@/_validations/buque/BuqueBodegaValidations';
import { DateFormater, NumberFormater } from '@/_helpers/funciones';
import { VMoney, Money } from "v-money";
import GeneralMixin from '@/_mixins/general';

import HatchCoverModal from './tapas-modal';

function fields(){
  if(this.isCargoVessel){
    return [
      { 
        key: 'RowNumber', 
        label: '#',
        _style: 'width:5%; text-align:center;',
        filter: false,
      },
      { key: 'VesselHoldName', label: this.$t('label.hold'), _style: 'width:27%' },
      { key: 'BaleNormal', label: this.$t('label.baleCapacity')+' M³' , _style: 'width:15%;text-align:center;', _classes:'text-center' },
      { key: 'BaleGrain', label: this.$t('label.grainCapacity')+' M³' , _style: 'width:15%;text-align:center;', _classes:'text-center' },
      { key: 'UltimoUsuario', label: this.$t('label.user'), _style: 'width:10%;'},
      { key: 'FormatedDate', label: this.$t('label.date'), _style: 'text-align:center; width:10%;', _classes:'text-center'},
      { key: 'Status', label: this.$t('label.status'), _style: ' width:10%; text-align:center;', _classes:'text-center'},
      { 
        key: 'Details', 
        label: '', 
        _style: 'width:100px', 
        sorter: false, 
        filter: false,
      }
    ];
  }else{
    return [
      { 
        key: 'RowNumber', 
        label: '#',
        _style: 'width:5%',
        filter: false,
      },
      { key: 'VesselHoldName', label: this.$t('label.hold'), _style: 'width:42%' },
      { key: 'UltimoUsuario', label: this.$t('label.user'), _style: 'width:15%;'},
      { key: 'FormatedDate', label: this.$t('label.date'), _style: 'text-align:center; width:15%;', _classes:'text-center'},
      { key: 'Status', label: this.$t('label.status'), _style: 'text-align:center; width:15%;'},
      { 
        key: 'Details', 
        label: '', 
        _style: 'width:100px', 
        sorter: false, 
        filter: false,
      }
    ];
  }
} 

//Created:
function created() { }

//Data:
function data() {
  return {
    valid: false,
    isSubmit: false,
    isLoading: false,
    modalHatchCover: false,
    hold: {},
    items: [],
    bodega: {
      VesselHoldId: '',
      VesselId: '',
      VesselHoldName: '',
      CubicMeters: '',
      Bale: 0,
      BaleOnGrain: 0,
      BaleOnDeck: 0,
      Volumen: 0,
      Status: 1
    },
    oldFgAct: false,
    edit: false,
    activePage: 1,
    vesselType: '',

    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
      maxlength: 18,
    },
  }
}

//Methods:
function ValidateFormControl(value, required) {
  value = NumberFormater.setNum(value);
  if (value == 0 && this.valid==true && required) {
    return 'form-control is-invalid';
  }else{
    if(value == 0  && this.valid==false){
      return 'form-control';
    }else{
      return 'form-control is-valid';
    }
  }
}

function getBodegas(IdBuque) {
  this.isLoading = true;

  this.$http.ejecutar('GET', 'VesselHold-by-vessel', { VesselId: IdBuque })
  .then(response => {
    this.items = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoading = false;
  });
}
function openModalNewHatch(hold) {
  this.hold = {...hold};
  this.modalHatchCover = true;
}
function submit() {
  try{
    this.$v.$touch();
    this.valid = true;
    if (this.$v.$error) {
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }
    if(!this.edit)
      this.addBodega();
    else
      this.editBodega();
  }catch(e){
    this.isSubmit = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: "error"
    });
  }
  
}
function addBodega() {
  this.isSubmit = true;
  let VesselHoldJson = {...this.formatedData()};

  this.$http.ejecutar('POST', 'VesselHold-insert', VesselHoldJson, { root: 'VesselHoldJson'})
  .then(response => {
    this.$notify({
      group: 'container',
      title: '¡'+this.$t('label.success')+'!',
      text: response.data.data[0].Response,
      type: "success"
    });
    this.resetInputs();
    this.getBodegas(this.buque.VesselId);
    this.openModalNewHatch(response.data.data[0]);
  }).catch(err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isSubmit = false;
  });
}
function editBodega() {
  this.isSubmit = true;
  let VesselHoldJson = {...this.formatedData()};

  this.$http.ejecutar('PUT', 'VesselHold-update', VesselHoldJson, { root: 'VesselHoldJson'})
  .then(response => {
    this.$notify({
      group: 'container',
      title: '¡'+this.$t('label.success')+'!',
      text: response.data.data[0].Response,
      type: "success"
    });
    this.resetInputs();
    this.getBodegas(this.buque.VesselId);
  }).catch(err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isSubmit = false;
  });
}
function resetInputs() {
  if(this.edit){
    this.bodega.Status = 1;
    this.edit = false;
  }
  this.valid = false;
  this.bodega.VesselHoldId = '';
  this.bodega.VesselHoldName = '';
  this.bodega.Bale = 0;
  this.bodega.BaleOnGrain = 0;
  this.bodega.BaleOnDeck = 0;
  this.bodega.Volumen = 0;
  this.bodega.Status = 1;
  this.$v.$reset();
}
function handleEdit(item) {
  this.bodega.VesselHoldId = item.VesselHoldId;
  this.bodega.VesselHoldName = item.VesselHoldName;
  if(this.isCargoVessel){
    this.bodega.Bale = item.Bale ?? 0;
    this.bodega.BaleOnGrain = item.BaleOnGrain ?? 0;
    this.bodega.BaleOnDeck = item.BaleOnDeck ?? 0;
  }else if(this.isGeneralCargo){
    this.bodega.Volumen = item.Volumen ?? 0;
  }

  this.bodega.Status = item.FgActVesselHold?1:0;
  this.oldFgAct = item.FgActVesselHold;
  this.edit = true;
  this.$v.$touch();
}
function formatedData() {
  let vesselHold = {
    VesselId: this.bodega.VesselId,
    VesselHoldName: this.bodega.VesselHoldName,
  }

  if (this.isCargoVessel) {
    vesselHold.Bale = NumberFormater.setNum(this.bodega.Bale);
    vesselHold.BaleOnGrain = NumberFormater.setNum(this.bodega.BaleOnGrain);
    vesselHold.BaleOnDeck = NumberFormater.setNum(this.bodega.BaleOnDeck);
  }else if (this.isGeneralCargo) {
    vesselHold.Volumen = NumberFormater.setNum(this.bodega.Volumen);
  }

  if(!this.edit){
    return {...vesselHold}
  } else {
    return {...vesselHold, Status: this.bodega.Status, VesselHoldId: this.bodega.VesselHoldId};
  }
}
function statusConfirmation(){
  if(!this.edit)
    this.submit();
  else{
    let status = this.oldFgAct?1:0;
    if(this.bodega.Status === status)
      this.submit();
    else{
      this.$swal.fire({
        text: `${this.$t('label.changeStatusQuestion')+' '} ${this.bodega.VesselHoldName}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#42AA91',
        cancelButtonColor: '#E1373F',
        confirmButtonText: this.$t('button.confirm'),
        cancelButtonText: this.$t('button.cancel')
      }).then((result) => {
        if (result.isConfirmed) {
          this.submit();
        }
      });
    }
  }
}

function handleBayHatchAdd(item, modal) {
  this.hold = {
    ...item,
    VesselId: this.buque.VesselId,
    VesselName: this.buque.VesselName,
    Imo: this.buque.Imo
  };

  if(modal == 'HATCH')
    this.modalHatchCover = true;
}
function handleSubmit() {
  this.hold = {};
}

function setInfoByVesselType() {
  this.vesselType = this.buque.TpVesselId ?? '';
}

//Computerd: 
function buqueIMO() {
  return this.buque && this.buque.VesselName && this.buque.Imo
    ?`${this.buque.VesselName} - ${this.buque.Imo}`
    :'';
}
function formatedBodegas() {
  return this.items.map((item) => Object.assign({}, item, {
    RowNumber: item.Nro,
    BaleNormal: item.Bale? NumberFormater.formatNumber(item.Bale, 2):'0,00',
    BaleGrain: item.BaleOnGrain? NumberFormater.formatNumber(item.BaleOnGrain, 2) : '0,00',
    BaleDeck: item.BaleOnDeck? NumberFormater.formatNumber(item.BaleOnDeck, 2) : '0,00',
    Volumen: item.Volumen ? NumberFormater.formatNumber(item.Volumen, 2) : '0,00',
    UltimoUsuario: item.TransaLogin? item.TransaLogin:'N/A',
    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
    _classes: item.Status != 'ACTIVO'? 'table-danger': '',
    _cellClasses: { Bale: 'text-center', BaleOnGrain: 'text-center' }
  }));
}
function isSubmitValid(){
  return this.$v.$invalid || this.isSubmit;
}
function statusOptions() {
  return [
    {
      value: 1,
      label: this.$t('label.ACTIVO')
    },
    {
      value: 0,
      label: this.$t('label.INACTIVO')
    }
  ]
}
function statusSelectColor(){
  return this.bodega.Status === 1;
}
function submitText() {
  return this.edit?this.$t('label.edit'):this.$t('label.add');
}

function isContainerShip() {
  return this.vesselType.toUpperCase() == process.env.VUE_APP_CONTAINER_SHIP_VESSEL_ID;
}
function isCargoVessel() {
  return [process.env.VUE_APP_BULK_VESSEL_ID, process.env.VUE_APP_ROLL_ON_ROLL_OFF_ID].includes(this.vesselType);
}
function isBulkVessel() {
  return this.vesselType.toUpperCase() == process.env.VUE_APP_BULK_VESSEL_ID;
}
function isGeneralCargo() {
  return this.vesselType.toUpperCase() == process.env.VUE_APP_GENERAL_CARGO_VESSEL_ID;
}

export default {
  name: 'buque-bodega',
  mixins: [GeneralMixin],
  components: {
    Money,
    HatchCoverModal
  },
  props: {
    buque: {
      type: Object,
      required: true,
      default: () => {}
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data,
  created,
  validations() {
    return BuqueBodegaValidations(this.isCargoVessel, this.isGeneralCargo);
  },
  directives: UpperCase,
  computed: {
    buqueIMO,
    formatedBodegas,
    isSubmitValid,
    statusOptions,
    submitText,
    isContainerShip,
    isCargoVessel,
    isBulkVessel,
    fields,
    isGeneralCargo,
  },
  methods: {
    ValidateFormControl,
    addBodega,
    editBodega,
    resetInputs,
    submit,
    handleEdit,
    getBodegas,
    formatedData,
    statusSelectColor,
    statusConfirmation,
    handleBayHatchAdd,
    handleSubmit,
    setInfoByVesselType,
    openModalNewHatch,
  },
  watch: {
    buque: function(newBuque) {
      if( Object.keys(newBuque).length != 0 && newBuque.VesselId) {
        this.getBodegas(newBuque.VesselId);
        this.bodega.VesselId = newBuque.VesselId;
      }
    },
    active: function(newVal) {
      if(newVal){
        if( Object.keys(this.buque).length != 0 && this.buque.VesselId) {
          this.getBodegas(this.buque.VesselId);
          this.bodega.VesselId = this.buque.VesselId;
          this.setInfoByVesselType();
        }
      }
    }
  }
}
</script>

<style lang="scss">
.center-cell {
  text-align: center;
}
 
</style>